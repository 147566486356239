import React, {FC} from 'react'
import './Checkbox.css'
import {PropsType} from './types'
import cn from 'classnames'

export const Checkbox: FC<PropsType> = (props) => {

    const {
        children,
        className,
        id = 'checkbox',
        ...other
    } = props

    return (
        <div className={cn(className, {
            'Checkbox': true,
        })}>
            <input id={id} type="checkbox"{...other}/>
            <span/>
            {children && (
                <label htmlFor={id}>{children}</label>
            )}
        </div>
    )
}