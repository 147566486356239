import {useEffect, useState} from "react";

type useCommissionType = {
    amount: number,
    minimal: number,
    commission: number,
}

export function useCommission({amount, minimal, commission}: useCommissionType): string {

    const [calculations, setCalculations] = useState<string>('0.0');

    function calc(): number {

        const result = (amount / 100 * commission) * 100;

        if (result > minimal) {
            return result
        } else {
            return minimal
        }
    }

    useEffect(() => {
        if (amount) {
            setCalculations(calc().toFixed(2))
        }
    }, [amount])

    return calculations
}
