import React, {FC, useEffect, useRef, useState} from 'react'
import {PropsType, SelectListElementsType} from './types'
import './Select.css'
import cn from 'classnames'
import {Input} from "../Input";
import {SelectElement} from "./components/SelectElement";
import {useBlurred} from "../hooks/useBlurred";
import {CompanyItem} from "./components/CompanyItem";
import {IoMdArrowDropdown} from "react-icons/io";

type SelectType = {
    selected?: SelectListElementsType | null,
    localList: SelectListElementsType[],
    setLocalList: (value: SelectListElementsType[]) => void
} & PropsType
export const Select: FC<SelectType> = (props) => {

    /* props */
    const {
        onFocus,
        support,
        className,
        selected,
        listElements = [],
        localList,
        setLocalList,
        onSelected = () => null,
        ...other
    } = props

    /* state */
    const [isFocus, setIsFocus] = useState<boolean>(false);
    const [selectedLocal, setSelectedLocal] = useState<number>(0)

    /* ref */
    const selectRef = useRef<null | HTMLDivElement>(null)
    useBlurred(selectRef, setIsFocus)

    /* methods */
    const handlers = {
        toggle() {
            setIsFocus(prev => !prev)
        },
        focus(event: React.FocusEvent<HTMLInputElement>) {
            if (onFocus) onFocus(event)
            setIsFocus(true)
        },
    }

    useEffect(()=>{
        if (!isFocus) {
            if (!selected?.value) {
                onSelected({
                    text: ''
                } as SelectListElementsType)
            } else {
                onSelected(props.selected as SelectListElementsType)
            }
        } else {
            setLocalList(listElements)
        }

    }, [isFocus])

    return (
        <div ref={selectRef} className={cn(className, {
            'Select': true,
            'Select--focused': isFocus
        })}>
            <Input
                onFocus={handlers.focus}
                support={
                    <button className='Select__support' onClick={handlers.toggle}>
                        {support || <IoMdArrowDropdown size={16}/>}
                    </button>
                }
                {...other}
            />
            {Boolean(listElements.length) && (
                <div className="Select__container">
                    {localList.map((element: SelectListElementsType, index: number) => (
                        <SelectElement
                            index={index}
                            key={element.id}
                            element={element}
                            selected={selectedLocal}
                            handlers={{onSelected, setIsFocus, setSelected: setSelectedLocal}}>
                            {element.children || element.text}
                        </SelectElement>
                    ))}
                </div>
            )}
        </div>
    )
}


export const SelectComponents = {
    CompanyItem
}
