import React, {FC} from 'react'
import './style.css'
import logo from '../images/logo.svg'
import {useTranslation} from "react-i18next";

export const Footer: FC = () => {

    const {t} = useTranslation()

    return (
        <footer className='Footer PaddingContainer'>
            <img alt='Логотип' src={logo}/>
            <nav>
                <a href='#Section-1'>{t('Footer.link1')}</a>
                <a href='#Section-2'>{t('Footer.link2')}</a>
                <a href='#Section-3'>{t('Footer.link3')}</a>
                <a href='#Section-4'>{t('Footer.link4')}</a>
                <a href='#Section-5'>{t('Footer.link5')}</a>
                <a href='#Section-6'>{t('Footer.link6')}</a>
            </nav>
        </footer>
    )
}
