import {Input} from "../../../../../../../ui/Input";
import {Button} from "../../../../../../../ui/Button";
import React from "react";
import './style.css'
import {useInput} from "../../../../../../../ui/hooks/useInput";
import {useThunk} from "../../../../../../../../api/methods/useThunk";
import {useErrors} from "../../../../../../../../api/methods/useErrors";
import {dealReducerThunk} from "../../../../../../../../store/reducers/deal";
import {useTranslation} from "react-i18next";


export const GetMoney = () => {
    const {setters, getters, thunk, dispatch} = useThunk(dealReducerThunk)
    const {t} = useTranslation()

    const form = {
        cardNumber: useInput(),
        refill_hash: useInput(),
        errors: useErrors({
            Fields: {
                card_number: '',
                refill_hash: ''
            },
            Errors: getters.errors
        })
    }


    const getMoneyHandler = () => {
        dispatch(thunk.refillDeal({
            //@ts-ignore
            card_number: String(form.cardNumber.value).replaceAll(' ', ''),
            refill_hash: form.refill_hash.value
        }))
    }


    return (
        <>
            <div className='SetAndGet__stage-title'>
                {t("SendAndGet.getCode.title")}
            </div>
            <Input
                error={form.errors.refill_hash}
                variant='border'
                placeholder={t("SendAndGet.getCode.inputHash", 'Код')}
                {...form.refill_hash}
            />
            <Input
                error={form.errors.card_number}
                mask='9999 9999 9999 9999'
                variant='border'
                placeholder={t("SendAndGet.getCode.inputCardNumber")}
                {...form.cardNumber}
            />
            <Button
                disabled={!(form.cardNumber.value && form.refill_hash.value)}
                onClick={getMoneyHandler}
            >
                {t('SendAndGet.getCode.button')}
            </Button>
        </>
    )
}