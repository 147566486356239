import {Dispatch} from "redux";
import {ISetters} from "../../../api/methods/types";
import {StateType} from "../../index";
import {Client, Deal, Feedback} from "../../../api";
import {ClientType, dealReducerActions} from "./index";
import {IClientCreate, IDealCreate, IDealRefill, IDealStart, IDealStatus, IFeedback} from "../../../api/types";
import {dealRoutes} from "../../../components/landings/pages/root/Sections/SendAndGet/Stages";
import {routes} from "../../../routes";

/* @Types */
interface IClient extends IClientCreate {
    clientType: ClientType
}

/* @Thunk */
export function dealThunk(setters: ISetters) {


    function createClient(props: IDealCreate, variant: 'enterData' | 'getCode') {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Client.create({phone: String(props.phone)})

            switch (response.status) {
                case 200:
                    break;
                case 201:
                    dispatch(createDeal({
                        ...props,
                        seller_id: response.data.data.id
                    }, variant))
                    break;
                case 204:
                    break;
                case 400:
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }


    function createDeal(props: IDealCreate, variant: 'enterData' | 'getCode') {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Deal.create(props)

            switch (response.status) {
                case 200:
                    break;
                case 201:
                    const {seller, buyer, id, status} = response.data.data;
                    dispatch(dealReducerActions.updateClient({clientType: "seller", data: seller}))
                    dispatch(dealReducerActions.updateClient({clientType: "buyer", data: buyer}))
                    dispatch(dealReducerActions.updateDeal({data: {id, status}}))

                    setters.history.push(dealRoutes.enterCard.sendMoney[variant])
                    break;
                case 204:
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }

    function startDeal(props: IDealStart, variant: 'enterData' | 'getCode') {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Deal.start(props)

            switch (response.status) {
                case 204:
                    setters.history.push(dealRoutes.loading.sendMoney[variant])
                    break;
                case 201:
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }


    function getStatus(props: IDealStatus) {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Deal.get(props);

            /* methods */
            function handleStatus(status: string
                | "started"
                | "sent_process"
                | "completed"
                | "approved"
                | "rejected"
                | "error"
                | "new"
            ) {
                if (["sent_process", "new", "approved"].includes(status)) {
                    //логика с пингование
                } else if (["rejected", "error"].includes(status)) {
                    setters.history.push(dealRoutes.reject.sendMoney.enterData)
                } else if (status === "started") {
                    dispatch(dealReducerActions.setRedirect({
                        redirect: response.data.data.redirect
                    }))
                } else if (status === "completed") {
                    dispatch(dealReducerActions.updateDeal(response.data.data))
                } else {
                    console.log(JSON.stringify(status))
                }
            }

            switch (response.status) {
                case 200:
                    const {status} = response.data.data;
                    handleStatus(status)
                    break;
                case 201:
                    break;
                case 204:
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }

    function getStatusWithOutRedirect(props: IDealStatus) {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Deal.get(props);

            /* methods */
            function handleStatus(status: string
                | "started"
                | "sent_process"
                | "completed"
                | "approved"
                | "rejected"
                | "error"
                | "new"
            ) {
                if (["started", "sent_process", "new"].includes(status)) {
                    //пингуем
                    setters.setLoading(true)
                } else if (["rejected", "error"].includes(status)) {
                    // страничка ошибки
                    setters.history.push(routes.deal.error)
                    setters.setLoading(false)
                } else if (status === "completed") {
                    // страничка успеха
                    setters.history.push(routes.deal.success)
                    setters.setLoading(false)
                } else if (status === "approved") {
                    if (response.data.data.refill_hash !== null) {
                        dispatch(dealReducerActions.updateDeal({
                            data: {
                                refill_hash: response.data.data.refill_hash,
                            }
                        }))
                        setters.history.push(dealRoutes.success.sendMoney.getCode)
                        setters.setLoading(false)
                    }
                } else {
                    setters.setLoading(true)
                    console.log(JSON.stringify(status))
                }
            }

            function Reject() {
                setters.setLoading(false)
                setters.history.push(routes.deal.error)
                setters.setErrors(response.data.data.errors)
            }

            switch (response.status) {
                case 200:
                    const {status} = response.data.data;
                    handleStatus(status)
                    break;
                case 201:
                    break;
                case 204:
                    break;
                case 400:
                    Reject()
                    break;
                case 401:
                    Reject()
                    break;
                case 404:
                    Reject()
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }


    /* DEAL */
    function refillDeal(props: IDealRefill) {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Deal.refill(props)

            switch (response.status) {
                case 200:
                    break;
                case 201:
                    dispatch(dealReducerActions.updateDeal({
                        data: {
                            id: response.data.data.id
                        }
                    }))
                    setters.history.push(dealRoutes.loading.getMoney)
                    break;
                case 204:
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }

    function getStatusGetMoney(props: IDealStatus) {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Deal.get(props);

            /* methods */
            function handleStatus(status: string
                | "started"
                | "sent_process"
                | "completed"
                | "approved"
                | "rejected"
                | "error"
                | "new"
            ) {
                if (["started", "sent_process", "new", "approved"].includes(status)) {
                    //пингуем
                    setters.setLoading(true)
                } else if (["rejected", "error"].includes(status)) {
                    // страничка ошибки
                    setters.history.push(routes.deal.error)
                    setters.setLoading(false)
                } else if (status === "completed") {
                    // страничка успеха
                    setters.history.push(routes.deal.success)
                    setters.setLoading(false)
                } else {
                    setters.setLoading(true)
                }
            }

            function Reject() {
                setters.setLoading(false)
                setters.history.push(routes.deal.error)
                setters.setErrors(response.data.data.errors)
            }

            switch (response.status) {
                case 200:
                    const {status} = response.data.data;
                    handleStatus(status)
                    break;
                case 201:
                    break;
                case 204:
                    break;
                case 400:
                    Reject()
                    break;
                case 401:
                    Reject()
                    break;
                case 404:
                    Reject()
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }


    /* FEEDBACK */
    function feedback(props: IFeedback) {
        return async function (dispatch: Dispatch<any>, getState: () => StateType) {

            const response = await Feedback.post(props)

            switch (response.status) {
                case 200:
                    setters.setData('OK')
                    break;
                case 201:
                    break;
                case 204:
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                case 401:
                    break;
                case 404:
                    break;
                case 500:
                    break;
                default:
                    break
            }
        }
    }

    return {
        feedback,
        getStatusGetMoney,
        startDeal,
        getStatus,
        createDeal,
        refillDeal,
        createClient,
        getStatusWithOutRedirect,
    }
};
