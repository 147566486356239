import React, {useState} from "react";

type ReturnedValueType = {
    checked: boolean
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

type PropsType = {
    initialValue: boolean,
    callback?(value: boolean): void
}

const initialProps = {
    initialValue: false,
    callback: () => null,
}

export type UseCheckboxReturnedValueType = ReturnedValueType

export const useCheckbox = (props: PropsType = initialProps): ReturnedValueType => {

    const {initialValue, callback = () => null} = props;

    /* state */
    const [checked, setChecked] = useState<boolean>(initialValue);

    /* methods */
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked)
        callback(event.target.checked)
    }

    return {
        checked,
        onChange: handleChange,
    }
}
