import React from 'react'
import {useTranslation} from "react-i18next";
import {Button} from "../../../../../../../../ui/Button";
import {StagesCloseButton} from "../../../../../../../ui/StagesCloseButton";
import {useHistory} from "react-router-dom";


export const SendMoneyEnterDataReject = () => {
    const {t} = useTranslation()
    const history = useHistory()

    const goBackHandler = () => {
        history.push('/deal/start/sendMoney/enterData')
    }

    return (
        <>
            <StagesCloseButton/>
            <div className='SetAndGet__stage-title'>{t("SendAndGet.reject.title")}</div>
            <div className='SetAndGet__stage-icon SetAndGet__stage-icon--reject'/>
            <div className='SetAndGet__stage-status-support'>
                {t("SendAndGet.reject.support")}
            </div>
            <Button onClick={goBackHandler}>
                {t("SendAndGet.reject.button")}
            </Button>
        </>
    )
}