import React from 'react'
import './style.css'
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {methods} from "../../../../../../../../../api/methods";
import {useThunk} from "../../../../../../../../../api/methods/useThunk";
import {dealReducerSelectors, dealReducerThunk} from "../../../../../../../../../store/reducers/deal";
import icon from './images/icon.png'

export const SendMoneyGetCodeLoading = () => {
    const {status, id} = useSelector(dealReducerSelectors.getDeal)
    const {setters, getters, dispatch, thunk} = useThunk(dealReducerThunk)
    const {t} = useTranslation()

    methods.usePing(() => {
        dispatch(thunk.getStatus({
            deal_id: Number(id)
        }))
    }, 3000)

    return (
        <div className='LoadingStage'>
            <img className='LoadingStage__icon' src={icon} alt=""/>
            <div className='LoadingStage__text'>{t('SendAndGet.loading')}</div>
        </div>
    )
}