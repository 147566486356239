import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {dealReducer} from "./reducers/deal";

const rootReducer = combineReducers({
    dealReducer: dealReducer.reducer,
})
export type StateType = ReturnType<typeof rootReducer>

export const store = configureStore({
    reducer: rootReducer
})
