import React, {FC} from 'react'
import './Button.css'
import {PropsType} from './types'
import cn from 'classnames'

export const Button: FC<PropsType> = (props) => {

    const {
        children,
        variant = 'basic',
        disabled,
        className,
        bgColor,
        ...other
    } = props;

    return (
        <button disabled={disabled} className={cn(className, {
            'Button': true,
            [`Button--${variant}`]: true,
            [`Button--${bgColor}`]: true
        })} {...other}>
            {children}
        </button>
    )
}