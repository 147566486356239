import {sliceAfter, sliceBefore} from "./MappingErrors";
import {StatusCases} from "./StatusCases";
import {useDebounce} from "./useDebounce";
import {useSetters} from "./useSetters";
import {Responses} from "./Responses";
import {usePing} from "./usePing";
import {useErrors} from "./useErrors";
import {useThunk} from "./useThunk";

export const methods = {
    StatusCases: StatusCases,
    useDebounce: useDebounce,
    useSetters: useSetters,
    useErrors: useErrors,
    sliceBefore: sliceBefore,
    sliceAfter: sliceAfter,
    Responses: Responses,
    useThunk: useThunk,
    usePing: usePing,
}
