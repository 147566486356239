import {IErrors, IErrorsFront} from './types'

/*
* Обрезает начало строки после ключевого символа
*
* @params { str } - строка в которой будем делать срез
* @params { after } - ключевой символ после которого будет срез
* @params { isTrim } - boolean параметр который отвечает за срез проблем, с начала и конца строки
* @params { typeSlice } - 'after' | 'before'
* @return - строка
* */

type SlicerType = {
    str: string,
    isTrim: boolean,
    position: string,
    typeSlice: 'after' | 'before'
}

function slicer(props: SlicerType) {
    const {str, isTrim, position, typeSlice} = props;
    const index = str.replace(position, '%%%').indexOf('%%%');
    if (index !== -1) {
        const result = (typeSlice === 'after') ? str.slice(index + position.length) : str.slice(0, index)
        return isTrim ? result.trim() : result
    } else return isTrim ? str.trim() : str
}

export function sliceAfter(str: string, after: string, isTrim: boolean = true): string {
    return slicer({
        str,
        isTrim,
        position: after,
        typeSlice: 'after'
    })
}

export function sliceBefore(str: string, before: string, isTrim: boolean = true): string {
    return slicer({
        str,
        isTrim,
        position: before,
        typeSlice: 'before'
    })
}

/*
* Проходится по массиву ошибок, вернувшегося из Backend
*
* @params { errorsArray } - массив с ошибками
* @return - Возвращает обьект, где "key" поле в форме, a "value" ошибка
* */

type ErrorsArrayType = IErrors;

export function MappingErrors(errorsArray?: ErrorsArrayType[]): object {
    let ObjectWithErrors: any = {}
    if (errorsArray) {
        errorsArray.map((item: ErrorsArrayType, index) => {
            if (item.field && item.message) {
                ObjectWithErrors[item.field] = sliceAfter(item.message, ':')
            } else if (item.field === null && item.message) {
                ObjectWithErrors[`error${index}`] = sliceAfter(item.message, ':')
            }
        })
    }
    return ObjectWithErrors
}
