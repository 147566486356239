const landing = {
    root: '/'
}

export const routes = {
    root: '/',
    deal: {
        error: '/operation/card-connect/error',
        success: '/operation/card-connect/success',
        loading: '/operation/:id/card-connect/loading'
    },
    landing
}
