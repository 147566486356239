import React, {useEffect, useRef, useState} from "react";
import './style.css'
import {Card} from "../../../../../../../../ui/Card";
import {Button} from "../../../../../../../../ui/Button";
import {StagesCloseButton} from "../../../../../../../ui/StagesCloseButton";
import {useSelector} from "react-redux";
import {CardValueType} from "../../../../../../../../ui/Card/types";

import {dealReducerSelectors, dealReducerThunk} from "../../../../../../../../../store/reducers/deal";
import {useErrors} from "../../../../../../../../../api/methods/useErrors";
import {useThunk} from "../../../../../../../../../api/methods/useThunk";
import {useTranslation} from "react-i18next";

export const GetCodeCard = () => {
    // state
    const {setters, getters, dispatch, thunk} = useThunk(dealReducerThunk)
    const {id} = useSelector(dealReducerSelectors.getDeal)
    const [card, setCard] = useState<CardValueType>({
        number: '',
        date: '',
        cvv: '',
    })
    const {t} = useTranslation()


    const fieldsErrors = useErrors({
        Fields: {
            card_number: '',
            exp_year: '',
            exp_month: '',
            cvv: '',
        },
        Errors: getters.errors
    })

    // handlers
    const sendDataHandler = () => {
        dispatch(thunk.startDeal({
            // @ts-ignore
            card_number: Number(card.number.replaceAll(' ', '')),
            deal_id: Number(id),
            card_holder: null,
            cvv: card.cvv,
            exp_month: Number(card.date.slice(0, 2)),
            exp_year: Number('20' + card.date.slice(3))
        }, 'getCode'))
    }

    /* effects */
    useEffect(()=>{
        window.scrollTo(
           0,  (document.querySelector('.Main__SendAndGet .Title') as HTMLDivElement)?.offsetTop
        )
    },[])

    return (
        <>
            <StagesCloseButton/>
            <h3 className='SetAndGet__stage-title'>{t('SendAndGet.sendMoney.card.title')}</h3>
            <Card error={fieldsErrors} onChangeCardValue={setCard}/>
            <Button
                onClick={sendDataHandler}
            >
                {t('SendAndGet.sendMoney.card.button')}
            </Button>
        </>
    )
}