import React, {FC, useEffect, useState} from "react";
import './style.css'
import {Title} from "../../../../ui/Title";
import {Input} from "../../../../../ui/Input";
import {TextArea} from "../../../../../ui/TextArea";
import {Button} from "../../../../../ui/Button";
import MapImage from '../../images/Map.png'
import {useInput} from "../../../../../ui/hooks/useInput";
import {useErrors} from "../../../../../../api/methods/useErrors";
import {useThunk} from "../../../../../../api/methods/useThunk";
import {dealReducerThunk} from "../../../../../../store/reducers/deal";
import {useTranslation} from "react-i18next";
import {Icons} from "../../images/icons";


export const Contacts: FC = () => {

    const {t} = useTranslation()
    const [status, setStatus] = useState('')
    const {getters, setters, dispatch, thunk} = useThunk(dealReducerThunk, {}, setStatus)
    const [text, setText] = useState('')

    const form = {
        email: useInput(),
        phone: useInput(),
        name: useInput(),
        errors: useErrors({
            Fields: {
                email: '',
                phone: '',
                name: '',
            },
            Errors: getters.errors
        })
    }

    const sendData = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault()
        dispatch(thunk.feedback({
            email: form.email.value,
            phone: form.phone.value,
            full_name: form.name.value,
            message: text,
        }))
    }

    return (
        <div id='Section-6' className='Main__Contacts'>
            <section className='Main__Contacts__feedback'>
                <Title>{t('Contacts.header')}</Title>
                <div className='Main__Contacts__form PaddingContainer'>
                    {status == 'OK'
                        ? <div className='Main__Contacts__success'>
                                <h4>{t("Contacts.success")}</h4>
                                <img src={Icons.IconSuccess}/>
                        </div>
                        : <>
                            <h3>{t('Contacts.title1')}</h3>
                            <form>
                                <Input
                                    error={form.errors.email}
                                    {...form.email}
                                    style={{gridArea: 'email'}}
                                    placeholder='Email'
                                />
                                <Input
                                    error={form.errors.phone}
                                    {...form.phone}
                                    style={{gridArea: 'number'}}
                                    placeholder={t("Contacts.input2")}
                                    mask='99999999999'
                                />
                                <Input
                                    error={form.errors.name}
                                    style={{gridArea: 'name'}}
                                    {...form.name}
                                    placeholder={t("Contacts.input3")}
                                />
                                <TextArea
                                    onChange={(event => setText((event.target as HTMLTextAreaElement).value))}
                                    style={{gridArea: 'text'}}
                                    placeholder={t("Contacts.input4")}
                                />
                                <Button onClick={sendData} style={{gridArea: 'button'}} bgColor='pink'>
                                    {t("Contacts.button")}
                                </Button>
                            </form>
                        </>}
                </div>
            </section>
            <section className='Main__Contacts__info'>
                <Title>{t('Contacts.title2')}</Title>
            </section>
        </div>
    )
}
