import React, {FC, ReactNode, useState} from "react";
import './style.css'
import {Title} from "../../../../ui/Title";
import cn from 'classnames'
import {useTranslation} from "react-i18next";


const Card = (props: { question: string, text: string | ReactNode }) => {
    const [state, setState] = useState(false)
    const cardTrigger = () => {
        setState(!state)
    }
    return (
        <div className='Main__FAQ__Card'>
            <div onClick={cardTrigger} className='Main__FAQ__Card__header'>
                <h4>{props.question}</h4>
                <div className={cn('Main__FAQ__Card__trigger', {
                    'Main__FAQ__Card__trigger--active': state
                })}/>
            </div>
            <p className={cn('Main__FAQ__Card__body', {
                'Main__FAQ__Card__body--opened': state
            })}>
                {props.text}
            </p>
        </div>
    )
}

export const FAQ: FC = () => {

    const {t} = useTranslation()

    const questions = [
        {
            question: t('FAQ.item1.title'),
            text: t('FAQ.item1.text'),
        },
        {
            question: t('FAQ.item2.title'),
            text: t('FAQ.item2.text'),
        },
        {
            question: t('FAQ.item3.title'),
            text: t('FAQ.item3.text'),
        }
    ]

    return (
        <div id='Section-5' className='Main__FAQ'>
            <Title>{t('FAQ.header')}</Title>
            <div className='Main__FAQ__body Grid PaddingContainer'>
                {questions.map((question, index) => (
                    <Card key={index} {...question}/>
                ))}
            </div>
        </div>
    )
}
