import React from 'react';
import './App.css';
import './components/ui/styles/index.css'
import {BrowserRouter, Route} from 'react-router-dom'
import {routes} from "./routes";
import {Landings} from "./components/landings";

function App() {

    return (
        <BrowserRouter>
            <div className="App">
                <Route pat={routes.root}>
                    <Landings/>
                </Route>
            </div>
        </BrowserRouter>
    );
}

export default App;
