import {useSetters, UseSettersPropsType} from "./useSetters";
import {IGetters, ISetters} from "./types";
import {useDispatch} from "react-redux";
import {Dispatch} from "react";

type ReturnedType<T> = {
    thunk: T
    dispatch: Dispatch<any>
    getters: IGetters
    setters: ISetters
}

export function useThunk<T>(Thunk: (setters: ISetters) => T, props: UseSettersPropsType = {}, setData?:any): ReturnedType<T> {

    const dispatch = useDispatch()
    const [getters, setters] = useSetters(props);

    const thunk = Thunk({...setters, setData});
    return {
        thunk,
        getters,
        setters,
        dispatch
    }
}
