import React, {FC} from "react";
import './CompanyItem.css'

type PropsType = {
    inactive?: boolean
    children?: string
    header?: string
    ogrn?: string
    inn?: string
}


function sliceHeader(header: string): string {
    if (header.length > 96) {
        return `${header.slice(0, 96)}...`
    }
    return header
}

export const CompanyItem: FC<PropsType> = (props) => {

    const {inactive, header, children, inn, ogrn} = props;

    return (
        <div className='CompanyItem'>
            <h5>{sliceHeader(String(children || header))}</h5>
            <p>ИНН: {inn}</p>
            <p>ОГРН: {ogrn}</p>
            {inactive && (
                <div className='CompanyItem__inactive'>
                    Компания не является действующей
                </div>
            )}
        </div>
    )
}