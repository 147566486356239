import {ISetters, IErrors} from './types';
import {sliceAfter} from "./MappingErrors";

type CallBack = () => void;

export const StatusCases = (setters: ISetters) => {
    return {
        case4XX: (error?: IErrors[], callback?: CallBack) => {
            if (callback) callback()
            if (error) {
                setters.setErrors(error as IErrors[])
                error.map(({message}) => setters.setError(
                    sliceAfter(String(message), ':')
                ))
            }
        },
        case500: (text: string = 'Внутрення ошибка!', callback?: CallBack) => {
            if (callback) callback()
            setters.setError(text)
        }
    }
}