import {methods} from './methods';
import {instance} from './axios';
import {
    IClientCreate,
    IClientUpdate,
    IDealApprove,
    IDealCreate,
    IDealRefill,
    IDealStart,
    IDealStatus,
    IFeedback
} from './types';

const {Responses} = methods;
const Headers = {
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
}

export const Client = {
    async get(email: string) {
        return await Responses(
            instance.get(`/client?email=${email}`)
        )
    },
    async create(props: IClientCreate) {
        return await Responses(
            instance.post('/client', props)
        )
    },
    async update({client_id, ...other}: IClientUpdate) {
        return await Responses(
            instance.put(`/client/${client_id}`, other)
        )
    },
}

export const Deal = {
    async create(props: IDealCreate) {
        return await Responses(
            instance.post('/operation', props, Headers)
        )
    },
    async get({deal_id}: IDealStatus) {
        return await Responses(
            instance.get(`/operation/${deal_id}`)
        )
    },
    async start({deal_id, ...other}: IDealStart) {
        return await Responses(
            instance.put(`/operation/${deal_id}/start`, other)
        )
    },
    async approve({deal_id, pa_res}: IDealApprove) {
        return await Responses(
            instance.put(`/operation/${deal_id}/approve`, {pa_res})
        )
    },
    async refill({refill_hash, card_number}: IDealRefill) {
        return await Responses(
            instance.put('/refill', {refill_hash, card_number})
        )
    },
}

export const Feedback = {
    post: async (props: IFeedback) => {
        return await Responses(
            instance.post('/feedback', props)
        )
    },
}
