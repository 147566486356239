import {useEffect, useState} from "react";
import {methods} from "./index";


type PropsType<T> = {
    Fields: T,
    Errors: any[],
    ErrorClassName?: string
}

export function useErrors<T>(props: PropsType<T>): T {

    const {
        Fields,
        Errors,
        ErrorClassName = 'Input__error'
    } = props

    const [errors, setError] = useState<T>(Fields)

    function mapping(errors: any[]) {
        let errorsObject: any = {};
        errors.map(err => errorsObject[err.field] = `⨯ ${methods.sliceAfter(err.message, ':')}`)
        setError({
            ...Fields,
            ...errorsObject
        })
    }

    useEffect(() => {
        if (Errors.length) mapping(Errors)
        return () => mapping([])
    }, [Errors])

    useEffect(() => {
        let errorInput = window.document.querySelector(`.${ErrorClassName}`)
        if (errorInput) errorInput.scrollIntoView({
            block: "center",
            behavior: "smooth"
        })
    }, [errors])

    return errors
}