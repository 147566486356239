import {Button} from "../../../../../../../../ui/Button";
import React from "react";
import {StagesCloseButton} from "../../../../../../../ui/StagesCloseButton";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom'

export const SendMoneyEnterDataSuccess = () => {
    const {t} = useTranslation()
    const history = useHistory()

    const goBack = () => {
        history.push('/')
    }

    return (
        <>
            <StagesCloseButton/>
            <div className='SetAndGet__stage-title'>{t('SendAndGet.sendMoney.success.title')}</div>
            <div className='SetAndGet__stage-icon SetAndGet__stage-icon--success'/>
            <Button
                onClick={goBack}
            >
                {t('SendAndGet.sendMoney.success.button')}
            </Button>
        </>
    )
}