import {Button} from "../../../../../../../../ui/Button";
import React, {useState} from "react";
import {MdContentCopy} from 'react-icons/md'
import './style.css'
import {StagesCloseButton} from "../../../../../../../ui/StagesCloseButton";
import {useSelector} from "react-redux";
import {dealReducerSelectors} from "../../../../../../../../../store/reducers/deal";
import {useTranslation} from "react-i18next";
import {useHistory} from 'react-router-dom'
import cn from 'classnames'


export const SendMoneyGetCodeSuccess = () => {
    const {refill_hash} = useSelector(dealReducerSelectors.getDeal)
    const {t} = useTranslation()
    const history = useHistory()
    const [copyAlertAnimation, setCopyAlertAnimation] = useState(false)

    /* methods */
    const goBack = () => {
        history.push('/')
    }
    const copyCode = () => {
        navigator.clipboard.writeText(String(refill_hash) || '')
        setCopyAlertAnimation(true)
    }
    const disableAlertAnimation = () => {
        setCopyAlertAnimation(false)
    }


    return (
        <>
            <StagesCloseButton/>
            <div className='SetAndGet__stage-title'>{t('SendAndGet.sendMoney.success.title')}</div>
            <div className='SetAndGet__stage-icon SetAndGet__stage-icon--success'/>
            <div className='SetAndGet__stage-status-support'>
                <div className='SetAndGet__transfer-code'>
                    <p>{t('SendAndGet.sendMoney.success.refillHash')}</p>
                    <div>
                        <p className='SetAndGet__transfer-code__code'>
                            {refill_hash}
                        </p>
                        <div
                            className={cn('SetAndGet__transfer-code__copy', {
                                'SetAndGet__transfer-code__copy--copied': copyAlertAnimation
                            })}
                        >
                            <MdContentCopy onClick={copyCode}/>
                            <div onAnimationEnd={disableAlertAnimation}>
                                {t('SendAndGet.sendMoney.success.copyHash', 'Скопировано')}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Button
                onClick={goBack}
            >
                {t('SendAndGet.sendMoney.success.button')}
            </Button>
        </>
    )
}