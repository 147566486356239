import React, {FC} from "react";
import './style.css'
import {Title} from "../../../../ui/Title";
import {StagesController} from "./Stages/StagesController";
import {useTranslation} from "react-i18next";


export const SendAndGet: FC = () => {

    const {t} = useTranslation()


    return (
        <div id='Section-3' className='Main__SendAndGet'>
            <Title>{t("SendAndGet.header")}</Title>
            <StagesController/>
        </div>
    )
}
