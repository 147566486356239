import React, {FC, HTMLAttributes} from 'react'
import './style.css'

export const Title:FC<HTMLAttributes<HTMLDivElement>> = (props) => {

    return (
        <h3 className={props.className + ' Title'} {...props}>
            {props.children}
        </h3>
    )
}