import {useFormattingValidDate} from "./useFormattingValidDate";
import {useInput, UseInputReturnedValueType} from "./useInput";
import {useSelect, UseSelectReturnedValueType} from "./useSelect";
import {useToggle, UseToggleReturnedValueType} from "./useToggle";
import {useCheckbox, UseCheckboxReturnedValueType} from "./useCheckbox";
import {useCounter, UseCounterReturnedValueType} from "./useCounter";
import {useValidation, UseValidationReturnedValueType} from "./useValidation";
import {useCodeField, UseCodeFieldReturnedValueType} from "./useCodeField";
import {useRadioGroup, UseRadioGroupReturnedValueType} from "./useRadioGroup";

export const hooks = {
    useInput,
    useSelect,
    useToggle,
    useCounter,
    useCheckbox,
    useCodeField,
    useValidation,
    useRadioGroup,
    useFormattingValidDate
}

export declare namespace HooksReturnedValueType {
    type Input = UseInputReturnedValueType
    type Select = UseSelectReturnedValueType
    type Toggle = UseToggleReturnedValueType
    type Counter = UseCounterReturnedValueType
    type Checkbox = UseCheckboxReturnedValueType
    type CodeField = UseCodeFieldReturnedValueType
    type Validation = UseValidationReturnedValueType
    type RadioGroup = UseRadioGroupReturnedValueType
}