import {SwitchWithText} from "../../../../../../../ui/SwitchWithText";
import {Input} from "../../../../../../../ui/Input";
import {Button} from "../../../../../../../ui/Button";
import React, {FC, useEffect, useState} from "react";
import {dealRoutes} from "../index";
import {SendMoneyCard} from "../SendMoneyEnterData/Card";
import {SendMoneyEnterDataSuccess} from "../SendMoneyEnterData/Success";
import {GetCodeCard} from "../SendMoneyGetCode/Card";
import {SendMoneyGetCodeSuccess} from "../SendMoneyGetCode/Success";
import {GetMoney} from "../GetMoney";
import {useInput} from "../../../../../../../ui/hooks/useInput";
import {useThunk} from "../../../../../../../../api/methods/useThunk";
import {dealReducerThunk} from "../../../../../../../../store/reducers/deal";
import {Trans, useTranslation} from "react-i18next";
import {Route, useHistory, useLocation} from 'react-router-dom'
import {SendMoneyEnterDataReject} from "../SendMoneyEnterData/Reject";
import {SendMoneyGetCodeReject} from "../SendMoneyGetCode/Reject";
import {SendMoneyEnterDataLoading} from "../SendMoneyEnterData/Loading";
import {SendMoneyGetCodeLoading} from "../SendMoneyGetCode/Loading";
import config from '../../../../../../../../config.json';
import {useCommission} from "../../../../../../../useCommission";
import {Checkbox} from "../../../../../../../ui/Checkbox";
import {useCheckbox} from "../../../../../../../ui/hooks/useCheckbox";
import {GetStatusAfter} from "../../../GetStatusAfter";
import {routes} from "../../../../../../../../routes";
import {hooks} from "../../../../../../../ui/hooks";
import countriesRu from "../../countries/ru.json";
import countriesEn from '../../countries/en.json'
import {Select} from "../../../../../../../ui/Select";
import {Support} from "../../../../../../../../supports";
import {GetMoneyLoading} from "../GetMoney/Loading";

const support = new Support()

export const StagesController: FC = () => {

    /* state */
    const {t, i18n} = useTranslation()
    const [sendOrGet, setSendOrGet] = useState<string>('sendMoney')
    const [enterDataOrGetCode, setEnterDataOrGetCode] = useState<string>('enterData')
    const [didMount, setDidMount] = useState(false)
    const {getters, thunk, dispatch} = useThunk(dealReducerThunk)
    const history = useHistory()
    const {pathname} = useLocation()

    // handlers
    const sendMoneyEnterHandler = () => {
        console.log(form)
        dispatch(thunk.createClient({
            amount: Number(form.amount.value),
            //@ts-ignore
            card_number: String(form.card_number.value)?.replaceAll(' ', ''),
            name: null,
            buyer_id: null,
            seller_id: null,
            description: null,
            sender_name: String(form.sender_name.value) || null,
            sender_city: String(form.sender_city.value) || null,
            sender_address: String(form.sender_address.value) || null,
            sender_country: form.sender_country.selected?.value || null,
            phone: support.clear(form.phone.value) || null
        }, 'enterData'))
    }

    const sendMoneyGetCodeHandler = () => {
        dispatch(thunk.createClient({
            amount: Number(form.amount.value),
            name: null,
            buyer_id: null,
            seller_id: null,
            description: null,
            sender_name: String(form.GetCode_sender_name.value) || null,
            sender_city: String(form.GetCode_sender_city.value) || null,
            sender_address: String(form.GetCode_sender_address.value) || null,
            sender_country: form.GetCode_sender_country.selected?.value || null,
            phone: support.clear(form.GetCode_phone.value) || null
        }, 'getCode'))
    }

    // // effects for stage
    useEffect(() => {
        if (didMount) {
            if (sendOrGet == 'sendMoney') {
                history.push(dealRoutes.start.sendMoney)
            } else {
                history.push(dealRoutes.start.getMoney)
            }
        }
    }, [sendOrGet])
    useEffect(() => {
        if (didMount) {
            if (enterDataOrGetCode == 'enterData') {
                history.push(dealRoutes.start.sendMoney)
            } else {
                history.push(dealRoutes.start.getCode)
            }
        }
    }, [enterDataOrGetCode])
    useEffect(()=>{
        setDidMount(true)
    },[])

    /* form */
    const form = {
        sender_name: useInput(),
        sender_city: useInput(),
        sender_address: useInput(),
        amount: useInput({initialValue: '', maxNum: 10000000}),
        card_number: useInput(),
        conditionsEnterData: useCheckbox(),
        conditionsGetCode: useCheckbox(),
        sender_country: hooks.useSelect({
            listElements: i18n.language == 'ru' ? countriesRu : countriesEn
        }),
        phone: useInput(),

        GetCode_sender_name: useInput(),
        GetCode_sender_city: useInput(),
        GetCode_sender_address: useInput(),
        GetCode_sender_country: hooks.useSelect({
            listElements: i18n.language == 'ru' ? countriesRu : countriesEn
        }),
        GetCode_phone: useInput(),
    }

    /* Вывод ошибок */
    useEffect(() => {
        if (getters.errors.length) {
            let error: string = ''
            getters.errors.map(({message}) => {
                error += `
                    ${message}
                `
            })
            alert(error)
        }
    }, [getters.errors])


    const commission = useCommission({
        amount: Number(form.amount.value),
        commission: config.commission.amount,
        minimal: config.commission.minimal_amount
    })

    /* switch handlers */
    useEffect(() => {
        if (pathname == dealRoutes.start.sendMoney) {
            setEnterDataOrGetCode('enterData')
            setSendOrGet('sendMoney')
        }
        if (pathname == dealRoutes.start.getCode) {
            setEnterDataOrGetCode('getCode')
            setSendOrGet('sendMoney')
        }
        if (pathname == dealRoutes.start.getMoney) {
            setSendOrGet('getMoney')
        }
    }, [pathname])


    return (
        <>
            <div className='Main__SendAndGet__header PaddingContainer'>
                <div className='Main__SendAndGet__header__form Grid'>
                    <Route exact path={[
                        dealRoutes.start.getCode,
                        dealRoutes.start.sendMoney,
                        dealRoutes.start.getMoney,
                        '/'
                    ]}>
                        <SwitchWithText
                            value={sendOrGet}
                            onChange={setSendOrGet}
                            values={[
                                {
                                    text: t("SendAndGet.toggle1.sendMoney"),
                                    value: 'sendMoney'
                                },
                                {
                                    text: t("SendAndGet.toggle1.getMoney"),
                                    value: 'getMoney'
                                }
                            ]}
                        />
                        <Route exact path={[
                            dealRoutes.start.getCode,
                            dealRoutes.start.sendMoney,
                            '/'
                        ]}>
                            <Input
                                type="number"
                                max='99999'
                                maxLength={2}
                                step=".01"
                                className='Main__SendAndGet__header__sum'
                                placeholder={t("SendAndGet.input1")}
                                {...form.amount}
                            />
                            <div className='Main__SendAndGet__header__commission'>
                                {t("SendAndGet.commission")} {commission} ₽
                            </div>
                        </Route>
                    </Route>
                    <div className='Main__SendAndGet__header__padding'/>
                </div>
            </div>
            <div className='Main__SendAndGet__body PaddingContainer Grid'>
                    <Route exact path={[
                        dealRoutes.start.getCode,
                        dealRoutes.start.sendMoney,
                        '/'
                    ]}>
                        <SwitchWithText
                            value={enterDataOrGetCode}
                            variant='purple'
                            onChange={setEnterDataOrGetCode}
                            values={[
                                {
                                    text: t("SendAndGet.toggle2.enterData"),
                                    value: 'enterData'
                                },
                                {
                                    text: t("SendAndGet.toggle2.getCode"),
                                    value: 'getCode'
                                }
                            ]}
                        />
                    </Route>
                    {/* контроллер ветки SendMoney/enterData  */}
                    <Route exact path={[dealRoutes.start.sendMoney, '/']}>
                        <Input
                            placeholder={t("SendAndGet.fullNameField", 'ФИО')}
                            {...form.sender_name}
                        />
                        <div className='SetAndGet__enterData__city'>
                            <Select
                                className='SetAndGet__input'
                                placeholder={t("SendAndGet.countryField", 'Страна')}
                                {...form.sender_country}
                            />
                            <Input
                                placeholder={t("SendAndGet.cityField", 'Город')}
                                {...form.sender_city}
                            />
                        </div>
                        <Input placeholder={t("SendAndGet.addressField", 'Адрес')} {...form.sender_address}/>
                        <Input mask='+7 (999) 999 99 99' placeholder='+7 (' {...form.phone}/>
                        <Input
                            className='Main__SendAndGet__body__number Input--border'
                            placeholder={t("SendAndGet.input2")}
                            mask='9999 9999 9999 9999'
                            {...form.card_number}
                        />

                        <Button
                            disabled={!(form.card_number.value
                                && form.amount.value
                                && form.conditionsEnterData.checked
                                && form.phone.value
                                && form.sender_country.selected?.value
                                && form.sender_address.value
                                && form.sender_city.value
                                && form.sender_name.value
                            )}
                            onClick={sendMoneyEnterHandler}
                        >
                            {t("SendAndGet.button1")}
                        </Button>
                        <div className='Main__SendAndGet__conditions'>
                            <Checkbox {...form.conditionsEnterData}/>
                            <Trans i18nKey='SendAndGet.conditions'>
                                <p>Я согласен с условиями <a download href='/offer.pdf' style={{color: '#700B5B'}}>оферты</a></p>
                            </Trans>
                        </div>
                    </Route>
                    <Route path={dealRoutes.success.sendMoney.enterData}>
                        <SendMoneyEnterDataSuccess/>
                    </Route>
                    <Route path={dealRoutes.reject.sendMoney.enterData}>
                        <SendMoneyEnterDataReject/>
                    </Route>
                    <Route path={dealRoutes.loading.sendMoney.enterData}>
                        <SendMoneyEnterDataLoading/>
                    </Route>
                    <Route exact path={[dealRoutes.enterCard.sendMoney.enterData]}>
                        <SendMoneyCard/>
                    </Route>

                    {/* контроллер ветки SendMoney/getCode  */}
                    <Route exact path={[dealRoutes.start.getCode]}>
                        <Input
                            placeholder={t("SendAndGet.fullNameField", 'ФИО')}
                            {...form.GetCode_sender_name}
                        />
                        <div className='SetAndGet__enterData__city'>
                            <Select
                                className='SetAndGet__input'
                                placeholder={t("SendAndGet.countryField", 'Страна')}
                                {...form.GetCode_sender_country}
                            />
                            <Input
                                placeholder={t("SendAndGet.cityField", 'Город')}
                                {...form.GetCode_sender_city}
                            />
                        </div>
                        <Input
                            placeholder={t("SendAndGet.addressField", 'Адрес')}
                            {...form.GetCode_sender_address}
                        />
                        <Input mask='+7 (999) 999 99 99'
                               placeholder='+7 (' {...form.GetCode_phone}
                        />

                        <Button
                            disabled={!(form.amount.value
                                && form.conditionsGetCode.checked
                                && form.GetCode_phone.value
                                && form.GetCode_sender_country.selected?.value
                                && form.GetCode_sender_address.value
                                && form.GetCode_sender_city.value
                                && form.GetCode_sender_name.value
                            )}
                            onClick={sendMoneyGetCodeHandler}
                        >
                            {t("SendAndGet.button1")}
                        </Button>
                        <div className='Main__SendAndGet__conditions'>
                            <Checkbox {...form.conditionsGetCode}/>
                            <Trans i18nKey='SendAndGet.conditions'>
                                <p>Я согласен с условиями <a>оферты</a></p>
                            </Trans>
                        </div>
                    </Route>
                    <Route path={dealRoutes.success.sendMoney.getCode}>
                        <SendMoneyGetCodeSuccess/>
                    </Route>
                    <Route path={dealRoutes.reject.sendMoney.getCode}>
                        <SendMoneyGetCodeReject/>
                    </Route>
                    <Route path={dealRoutes.loading.sendMoney.getCode}>
                        <SendMoneyGetCodeLoading/>
                    </Route>
                    <Route exact path={[dealRoutes.enterCard.sendMoney.getCode]}>
                        <GetCodeCard/>
                    </Route>

                    {/* контроллер ветки GetMoney  */}
                    <Route exact path={[dealRoutes.start.getMoney]}>
                        <GetMoney/>
                    </Route>
                    <Route exact path={[dealRoutes.loading.getMoney]}>
                        <GetMoneyLoading/>
                    </Route>

                    {/*{Логика после редиректа с сервера}*/}
                    <Route path={routes.deal.error}>
                        <SendMoneyEnterDataReject/>
                    </Route>
                    <Route path={routes.deal.success}>
                        <SendMoneyEnterDataSuccess/>
                    </Route>
                    <Route path={routes.deal.loading}>
                        <GetStatusAfter/>
                    </Route>
                    {/*{Логика после редиректа с сервера}*/}
            </div>
        </>
    )
}
