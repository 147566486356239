import React from "react";
import {Route} from "react-router-dom";
import {routes} from "../../routes";
import {Main} from "./pages/root";
import './ui/style.css'


export function Landings() {
    return (
        <div className='landing'>
            <Route path={routes.landing.root}>
                <Main/>
            </Route>
        </div>
    )
}
