import React, {FC} from "react";
import './style.css'
import {Title} from "../../../../ui/Title";
import {useTranslation} from "react-i18next";
import CommissionImg from '../../images/Comission.png'

export const Commission: FC = () => {

    const {t} = useTranslation()

    return (
        <div id='Section-4' className='Main__Commission'>
            <Title>{t('Commission.header')}</Title>
            <div className='Main__Commission__body PaddingContainer Grid'>
                <div className='Main__Commission__left'>
                    <h5>{t('Commission.text1')}</h5>
                    {/*<h6>{t('Commission.text2')}</h6>*/}
                </div>
                <div className='Main__Commission__right'>
                    <img src={CommissionImg}/>
                    <div className='Main__Commission__right__text'>
                        <h5>{t('Commission.text1')}</h5>
                        {/*<h6>{t('Commission.text2')}</h6>*/}
                    </div>
                </div>
            </div>
        </div>
    )
}
