import React, {FC} from "react";
import './style.css'
import {Button} from "../../../../../ui/Button";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {dealRoutes} from "../SendAndGet/Stages";

export const FirstSection: FC = () => {


    const {t} = useTranslation()

    const goToForm = () => {
        let top = document.getElementById('Section-3')!.offsetTop;
        window.scrollTo(0, top);
    }
    return (
        <div id='Section-1' className='Main__FirstSection'>
            <div className='Main__FirstSection__content'>
                <h1>Cardsender</h1>
                <h2>{t("FirstSection.text1")}</h2>
                <div className='Main__FirstSection__buttons'>
                    <Link to={dealRoutes.start.sendMoney}>
                        <Button onClick={goToForm}>
                            {t("FirstSection.button1")}
                        </Button>
                    </Link>
                    <Link to={dealRoutes.start.getMoney}>
                        <Button onClick={goToForm} bgColor='pink'>
                            {t("FirstSection.button2")}
                        </Button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
