import React from "react"
import {useThunk} from "../../../../../../api/methods/useThunk";
import {dealReducerThunk} from "../../../../../../store/reducers/deal";
import {usePing} from "../../../../../../api/methods/usePing";
import {useLocation} from "react-router-dom";
import {Support} from "../../../../../../supports";
import icon from "../SendAndGet/Stages/SendMoneyEnterData/Loading/images/icon.png";
import {useTranslation} from "react-i18next";

export const GetStatusAfter = () => {

    const {pathname} = useLocation()
    const {t} = useTranslation()
    const {getters, thunk, dispatch} = useThunk(dealReducerThunk, {
        initialLoading: true
    })


    usePing(() => {
        if (getters.loading) {
            dispatch(thunk.getStatusWithOutRedirect({
                deal_id: new Support().getNumber(pathname)
            }))
        }
    }, 3000)

    return (
        <div className='LoadingStage'>
            <img className='LoadingStage__icon' src={icon} alt=""/>
            <div className='LoadingStage__text'>{t('SendAndGet.loading')}</div>
        </div>
    )
}