import React, {FC, ReactNode} from "react";
import './style.css'
import {Title} from "../../../../ui/Title";
import {useTranslation} from "react-i18next";


const Card = ({title, text}: { title: string | ReactNode, text: string | ReactNode }) => (
    <div className='Main__Benefits__Card'>
        <h4>{title}</h4>
        <p>{text}</p>
    </div>
)

export const Benefits: FC = () => {

    const {t} = useTranslation()

    const cardsData = [
        {
            title: t("Benefits.item1.title"),
            text: t("Benefits.item1.text")
        },
        {
            title: t("Benefits.item2.title"),
            text: t("Benefits.item2.text")
        },
        {
            title: t("Benefits.item3.title"),
            text: t("Benefits.item3.text")
        }
    ]


    return (
        <div id='Section-2' className='Main__Benefits PaddingContainer'>
            <Title>{t("Benefits.header")}</Title>
            <div className='Main__Benefits__Cards Grid'>
                {cardsData.map((card, index) => <Card key={index} {...card}/>)}
            </div>
        </div>
    )
}
