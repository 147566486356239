import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {dealThunk} from "./thunk";
import {StateType} from "../../index";

/* @types */
export interface IClient {
    id: number | null
    email: string | null
    last_name: string | null
    first_name: string | null
    middle_name: string | null
    card_number?: string | null
}

export interface IDeal {
    id: string | null
    name: string | null
    amount: number | null
    status: string | null
    description: string | null
    refill_hash: string | null
    refill_card_number: string | null
}

export interface IRedirect {
    url: string,
    method: string,
    params: object[]
}

export type ClientType = 'seller' | 'buyer'

/* @Reducer */
export type InitialStateType = {
    deal: IDeal
    buyer: IClient
    seller: IClient,
    redirect: IRedirect | null
}

const initialState: InitialStateType = {
    deal: {
        id: null,
        name: null,
        status: null,
        amount: null,
        description: null,
        refill_hash: null,
        refill_card_number: null
    },
    buyer: {
        id: null,
        email: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        card_number: null,
    },
    seller: {
        id: null,
        email: null,
        last_name: null,
        first_name: null,
        middle_name: null,
        card_number: null,
    },
    redirect: null
}

export const dealReducer = createSlice({
    name: '@dealReducer',
    initialState,
    reducers: {
        setDeal(state, {payload}: PayloadAction<{ data: IDeal }>) {
            state.deal = payload.data;
        },
        setClient(state, {payload}: PayloadAction<{ clientType: ClientType, data: IClient }>) {
            state[payload.clientType] = payload.data;
        },
        updateDeal(state, {payload}: PayloadAction<{ data: IDeal | any }>) {
            state.deal = {...state.deal, ...payload.data};
        },
        updateClient(state, {payload}: PayloadAction<{ clientType: ClientType, data: IClient | any }>) {
            state[payload.clientType] = {...state[payload.clientType], ...payload.data};
        },
        setRedirect(state, {payload}: PayloadAction<{ redirect: IRedirect | null }>) {
            console.log("setRedirect - payload.redirect", payload.redirect)
            state.redirect = payload.redirect
        }
    }
})


/* @Exports */
export const dealReducerSelectors = {
    getState: (state: StateType) => state.dealReducer,
    getDeal: (state: StateType) => state.dealReducer.deal,
    getBuyer: (state: StateType) => state.dealReducer.buyer,
    getSeller: (state: StateType) => state.dealReducer.seller,
    getRedirect: (state: StateType) => state.dealReducer.redirect,
};
export const dealReducerActions = dealReducer.actions;
export const dealReducerThunk = dealThunk;
