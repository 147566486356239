import React, {FC, useState} from "react";
import './style.css'
import logo from './images/logo.svg'
import {Link} from 'react-router-dom'
import {routes} from "../../../routes";
import {Button} from "../../ui/Button";
import cn from 'classnames'
import {useTranslation} from "react-i18next";
import classNames from "classnames";
import {dealRoutes} from "../pages/root/Sections/SendAndGet/Stages";

export const Header: FC = () => {

    const {t, i18n} = useTranslation()
    const [isNavOpened, setIsNavOpened] = useState(false)

    /* methods */
    const toggleNav = () => {
        setIsNavOpened(!isNavOpened)
    }
    function handleChangeLang({currentTarget: {id}}: React.MouseEvent<HTMLButtonElement>) {
        i18n.changeLanguage(id).finally()
    }
    const goToForm = () => {
        let top = document.getElementById('Section-3')!.offsetTop;
        window.scrollTo(0, top);
    }

    return (
        <div className='Header'>
            <Link to={routes.landing.root} className='Header__logo' aria-label='ссылка на главную'>
                <img src={logo} alt="Логотип"/>
            </Link>
            <div onClick={toggleNav} className='Header__burger'/>
            <div className={cn('Header__links', {
                'Header__links--opened': isNavOpened
            })}>
                <div onClick={toggleNav} className='Header__nav-close'/>
                <nav>
                    <a href='#Section-1'>{t('Footer.link1')}</a>
                    <a href='#Section-2'>{t('Footer.link2')}</a>
                    <a href='#Section-3'>{t('Footer.link3')}</a>
                    <a href='#Section-4'>{t('Footer.link4')}</a>
                    <a href='#Section-5'>{t('Footer.link5')}</a>
                    <a href='#Section-6'>{t('Footer.link6')}</a>
                </nav>
                <div className="Header__change-lang">
                    <button
                        id="ru"
                        className={cn({"active": i18n.language === "ru"})}
                        onClick={handleChangeLang}>
                        Ru
                    </button>
                    <button
                        id="en"
                        className={cn({"active": i18n.language === "en"})}
                        onClick={handleChangeLang}>
                        En
                    </button>
                </div>
                <Link to={dealRoutes.start.sendMoney}>
                    <Button onClick={goToForm} bgColor='gradient'>{t('Header.sendMoney', 'FIXME')}</Button>
                </Link>
            </div>
        </div>
    )
}
