import React, {FC, HTMLAttributes, useContext} from 'react'
import './style.css'
import {useHistory} from 'react-router-dom'

export const StagesCloseButton:FC<HTMLAttributes<HTMLDivElement>> = (props) => {
    const history = useHistory()

    const goBack = () => {
        history.push('/')
    }

    return (
        <div role='button' onClick={goBack} className={props.className + ' StagesCloseButton'} {...props}/>
    )
}